<template>
    <div class="modal-content">
        <!--<p @click="closeModal()" class="close"> &times;</p>-->
        <div class="modal-opc">
            <a @click="closeModal()" href="/barragan">
                <p>Home</p>
            </a>
            <a @click="closeModal(), redirect('nosotros')">
                <p>Nosotros</p>
            </a>
            <a @click="closeModal()" href="/barragan/servicios">
                <p>Servicios</p>
            </a>
            <a @click="closeModal(), redirect('certificaciones')">
                <p>Certificaciones</p>
            </a>
            <a @click="closeModal(), showView()" href="#">
                <p>Quejas</p>
            </a>
            <a @click="closeModal(), redirect('contacto')">
                <p>Contacto</p>
            </a>
        </div>

        <p class="ClienteButton" :class="{ selector: this.opcion == 'Contacto' }"><a href="/login">Acceso a Clientes<img
                    src="../../assets/Vector3.svg"></a></p>

        <a href="/laason">
            <div class="ChangeScreen">
                <p>Cambiar a LAASON</p>
                <img src="../../assets/logoMini.svg">
            </div>
        </a>
    </div>
</template>

<script>


export default {
    name: 'LaasonModal',
    data() {
        return {
            showmodal: false,
            modalopc: ""
        }
    },
    methods: {
        closeModal: function () {
            this.$emit('listenModal', this.showmodal)
        },

        showView() {
            this.$emit('quejas')
        },

        redirect(direccion) {
            if (this.$route.name === 'HomeBarragan') {
                window.location.href = `#${direccion}`
            } else {
                this.$router.push("/barragan");

                setTimeout(() => {
                    window.location.href = `#${direccion}`
                }, 600);
            }
        }
    }
}
</script>

<style scoped>
.modal-content {
    background-image: url('../../assets/LG.svg');
    margin: 1% auto;
    /* 15% from the top and centered */
    width: 100%;
    height: 142.4009661835749VW;
}

.close {
    color: rgb(83, 83, 83);
    float: right;
    font-size: 7.7vw;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal-opc {
    text-align: center;
    margin-top: 7vw;
}

.modal-opc p {
    margin-bottom: 11.594202898550725VW;
    margin-top: 0vw;
}

.modal-opc a {
    color: white;
    font-size: 4.830917874396135VW;
    font-weight: 600;
}

.ModalLaason {
    position: absolute;
    margin: -49vw 19vw;
}

.ClienteButton a {
    background-color: #0A4CA1;
    color: #FFFFFF !important;
    font-weight: 900;
    font-size: 3.6231884057971016VW;
    border-radius: 8vw;
    padding: 5.372464VW 19.903382VW;
    text-decoration: none;
    margin-left: 10vw;
}

.ClienteButton img {
    width: 4.1062801932367154VW;
    height: auto;
    margin-left: 3.6231884057971016VW;
    padding-bottom: 0.15vw;
}

.ChangeScreen {
    width: 100%;
    height: 18.115942028985508VW;
    background-color: white;
    color: #6FBA91;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0vw 27vw;
    margin-top: 19vw;

}

.ChangeScreen p {
    font-size: 3.6231884057971016VW;
    font-weight: 900;
    margin: 0vw;
}

.ChangeScreen img {
    width: 5.072463768115942VW;
    height: auto;
}
</style>