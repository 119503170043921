<template>
    <div>
        <HeaderB />
        <div class="Bloque1">

            <div class="B1F1">
                <div class="B1F1C1">
                    <div class="B1F1C1_C1">
                        <img src="../../assets/logo1_1.png">
                    </div>
                    <div class="B1F1C1_C2">
                        <p>LABORATORIO CLÍNICO BARRAGÁN</p>
                    </div>
                </div>

                <div class="B1F1C2">
                    <div class="B1F1C2_C1">
                        <ul>
                            <li @click="cambiarOpcion('Home'), redirect('/')"
                                :class="{ selector: this.opcion == 'Home' }">
                                Inicio</li>
                            <li @click="cambiarOpcion('Nosotros'), redirect('nosotros')"
                                :class="{ selector: this.opcion == 'Nosotros' }">Nosotros</li>
                            <li @click="cambiarOpcion('Servicios')" :class="{ selector: this.opcion == 'Servicios' }"><a
                                    href="/barragan/servicios">Servicios</a></li>
                            <li @click="cambiarOpcion('Certificaciones'), redirect('certificaciones')"
                                :class="{ selector: this.opcion == 'Certificaciones' }">Certificaciones</li>
                            <li @click="cambiarOpcion('Contacto'), redirect('contacto')"
                                :class="{ selector: this.opcion == 'Contacto' }">Contacto</li>
                            <li @click="cambiarOpcion('Privacidad')" :class="{ selector: this.opcion == 'Privacidad' }">
                                <a href="/barragan/politica">Aviso Privacidad</a>
                            </li>
                            <li :class="{ selector: this.opcion == 'Quejas' }">
                                <a href="#" @click="cambiarOpcion('Quejas')">Quejas</a>

                                <div class="wrapper" :class="{ 'extended': opcion == 'Quejas' }">
                                    <div>
                                        <h2>¿Tienes alguna queja o sugerencia para nuestro servicio? </h2>
                                        <a href="mailto:dirección@laason.mx">Envíanos un mensaje</a>
                                        <ol>
                                            <li>Envíanos un mensaje por medio de este botón o al correo <a
                                                    href="mailto:dirección@laason.mx">dirección@laason.mx</a>
                                            </li>
                                            <li>Te confirmaremos de recibida tu queja o sugerencia, si es una queja
                                                recibe y
                                                analiza las causas que la originaron si procede o no en un plazo máximo
                                                de
                                                10 días hábiles.</li>
                                            <li>Se tomaran las acciones pertinentes y nos comunicaremos contigo.</li>
                                        </ol>
                                        <h3>Muchas gracias por ser parte de nuestra mejora continua.</h3>

                                        <button @click="cambiarOpcion('Home')">Salir</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div class="B1F2">

                <img class="B1F2C2" src="../../assets/ErrorL.png">

                <div class="textos">
                    <h1>Error 404</h1>
                    <p>Parece que algo salió mal. Lamentamos las molestias.</p>
                    <a href="/barragan">Regresar a Inicio</a>

                </div>
                <img class="B1F2C2_P" src="../../assets/ErrorL.png">

            </div>

        </div>
    </div>
</template>

<script>

import HeaderB from '../HeaderB.vue';

export default {
    name: 'ErrorBarragan',
    data() {
        return {
            opcion: "",
            opc: "barraganService",

        }
    }, components: {
        HeaderB
    },
    computed: {



    },
    methods: {
        cambiarOpcion(opcion) {
            this.opcion = opcion;
        },
        routaR() {
            console.log(this.$route.path.slice(0, 10))
        },

        redirect(direccion) {
            this.$router.push("/barragan")

            setTimeout(() => {
                window.location.href = `#${direccion}`
            }, 600);
        }
    }
}
</script>

<style scoped>
.selector {
    border: 1px solid white;
    border-radius: 3vw;
    text-align: center;
    padding: 0.36875VW 1.6666666666666667VW;
    margin: 0vw !important;
}

.Bloque1 {
    display: block;
    width: 100%;
    height: 202.41545893719808VW;
    background: linear-gradient(243.78deg, #6FBA91 17.49%, #0A4CA1 99.32%);
    text-align: -webkit-center;
    padding-top: 0vw;
}

.B1F1 {
    width: 86.88405797101449VW;
    border-bottom: 1px solid white;
    padding-bottom: 4.830917874396135VW;
    margin-bottom: 6.038647342995169VW;
}

.B1F1C1 {
    display: flex;
    align-items: center;
}

.B1F1C1_C1 {
    margin-top: 4vw;
}

.B1F1C1_C1 img {
    width: 13.285024154589372VW;
    height: auto;
}

.B1F1C1_C2 {
    width: 32vw;
    height: 9vw;
    margin-left: 3.3816425120772946VW;
    padding-top: 5vw;
}

.B1F1C1_C2 p {
    color: white;
    font-size: 3.140096618357488VW;
    line-height: 4.270531400966184VW;
    font-weight: 700;
    text-align: left;
}

.B1F1C2 {
    display: none;
}

.B1F2C1 a {
    font-size: 3.140096618357488VW;
    padding: 4.1VW 29.9VW;
    border-radius: 8vw;
    margin-left: 0VW;
    color: white;
    background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
}

img.B1F2C2_P {
    width: 60.38647342995169VW;
    height: auto;
    margin-top: 13.3vw;
}

img.B1F2C2 {
    display: none;
}

.B1F2C1_F1 p {
    color: #BBEFE3;
    font-size: 12.077294685990339VW;
    font-weight: 800;
    margin-bottom: 6.038647342995169VW;
    line-height: 7.971014492753622VW;
}

.B1F2C1_F1 span {
    font-size: 5.5VW;
}

.B1F2C1_F2 p {
    width: 61vw;
    color: white;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    margin-bottom: 7vw;
}


.textos {
    margin-top: 18vw;
}

.textos h1 {
    color: #BBEFE3;
    font-size: 13.285024154589372VW;
    font-weight: 900;
}

.textos p {
    width: 42vw;
    color: white;
    font-size: 3.6231884057971016VW;
    font-weight: 400;
    margin-bottom: 11.83574879227053VW;
}

.textos a {
    color: white;
    background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
    font-size: 3.140096618357488VW;
    font-weight: 900;
    padding: 5.072463768115942VW 11.594202898550725VW;
    border-radius: 10vw;
}


@media (min-width: 768px) {

    .textos {
        margin-top: 9.458333333333332VW;
        margin-left: 5.208333333333334VW;
        text-align: -webkit-left;
    }

    .textos h1 {
        color: #BBEFE3;
        font-size: 2.864583333333333VW;
        font-weight: 900;
    }

    .textos p {
        color: white;
        font-size: 0.78125VW;
        font-weight: 400;
        margin-bottom: 3.0729166666666665VW;
    }

    .textos a {
        color: white;
        background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
        font-size: 0.6770833333333334VW;
        font-weight: 900;
        padding: 1.1458333333333333VW 2.5520833333333335VW;
        border-radius: 10vw;
    }


    .Bloque1 {
        background: linear-gradient(243.78deg, #6FBA91 17.49%, #0A4CA1 99.32%);
        display: block;
        height: 49.947916666666664VW;
        width: 100%;
        padding-top: 0vw;
    }

    .B1F1 {
        display: flex;
        place-content: center;
        margin: 0vw 6vw;
        padding-bottom: 1vw;
        border-bottom: 1px solid white;
    }

    .B1F1C1 {

        margin-right: 33vw;
    }

    .B1F1C1_C1 {
        margin-top: 0.1VW;
        margin-right: 1.6145833333333335VW;
    }

    .B1F1C1_C1 img {
        width: 4.53125VW;
        height: auto;
    }

    .B1F1C1_C2 {
        width: 8vw;
        height: 1.5vw;
        padding-top: 0.5vw;
        text-transform: uppercase;
        margin-left: 0vw;
    }

    .B1F1C1_C2 p {
        font-size: 0.78125VW;
        line-height: 0.9208333333333334VW;
        text-align: left;
    }

    .B1F1C2 {
        display: flex;
        align-items: center;
    }

    .B1F1C2_C1 ul {
        display: flex;
        color: white;
        list-style: none;
        font-weight: 500;
        font-size: 0.6770833333333334VW;
        margin-bottom: 0vw;
    }

    .B1F1C2_C1>ul>li {
        cursor: pointer;
        align-self: center;
    }

    .B1F1C2_C1>ul>li:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .B1F1C2_C1>ul>li:first-of-type:hover {
        text-decoration: underline;
    }

    .B1F1C2_C1>ul>li {
        width: 5.989583333333334vw;
        height: 1.8229166666666667vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .B1F1C2_C1>ul>li>a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .B1F1C2_C1 li {
        cursor: pointer;
        align-self: center;
        position: relative;
    }

    .B1F1C2_C1 a {
        color: white;
    }

    .B1F2 {
        display: flex;
    }

    .B1F2C1 {
        width: 52vw;
        height: 10vw;
        margin-top: 4.739583VW;
    }

    .B1F2C1_F1 {
        margin-left: 13vw;
    }

    .B1F2C1_F1 p {
        color: #BBEFE3;
        font-weight: 800;
        font-size: 5.208333333333334VW;
        line-height: 3VW;
        margin-bottom: 2VW;
        text-align: left;
    }

    .B1F2C1_F1 span {
        font-size: 2.083333333333333VW;
    }

    .B1F2C1_F2 {
        width: 20vw;
        text-align: left;
        margin-left: -6vw;
    }

    .B1F2C1_F2 p {
        width: 20vw;
        color: white;
        font-weight: 400;
        font-size: 0.78125VW;
        line-height: 1.1213541666666667VW;
        margin-bottom: 3vw;
    }

    .B1F2C1 a {
        font-size: 0.6770833333333334VW;
        color: white;
        padding: 1.1VW 3.9VW;
        background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
        border-radius: 8vw;
        margin-left: -16VW;
        cursor: pointer;
    }

    .B1F2C2 {
        display: block !important;
        width: 27.500000000000004VW !important;
        height: auto !important;
        margin-left: 21.197916666666668VW;
        margin-bottom: 0vw;
        margin-top: -2.6vw !important;
    }

    .B1F2C2_P {
        display: none;
    }

    .wrapper {
        box-sizing: border-box;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.3s linear;
        position: absolute;
        background-image: linear-gradient(0deg, #183B68 81.32%, rgba(48, 117, 206, 0.00) 100%);

        width: 20.885416666666668vw;
        border-radius: 0 0 1.0416666666666665vw 1.0416666666666665vw;
        top: 100%;
        left: -11.854166666666668vw;
    }

    .wrapper.extended {
        grid-template-rows: 1fr;
    }

    .wrapper * {
        box-sizing: border-box;
    }

    .wrapper>div {
        overflow: hidden;
        padding: 0 2.1875vw;
    }

    .wrapper h2 {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        font-size: 1.25vw;
        line-height: 100%;
        margin: 5vw 0 0.8333333333333334vw;
    }

    .wrapper h2+a {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(0deg, #4F8B77 0%, #6FBA91 100%);
        color: #FFF;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;

        font-size: 0.9895833333333333vw;
        line-height: normal;
        width: 100%;
        height: 2.7604166666666665vw !important;
        border-radius: 2.604166666666667vw;
        margin: 0 0 1.1979166666666667vw;


        width: 100%;
        height: 2.7604166666666665vw !important;
        border-radius: 2.604166666666667vw;
        margin: 0 0 1.1979166666666667vw;
    }

    .wrapper ol {
        padding: 0 0 0 1.0416666666666665vw;
    }

    .wrapper li {
        margin: 0;
        text-align: start;
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;

        font-size: 0.8333333333333334vw;
        line-height: 150%;
    }

    .wrapper li a {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        display: inline !important;
        font-size: 0.8333333333333334vw;
        line-height: 1.25vw;
    }

    .wrapper h3 {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        font-size: 0.8333333333333334vw;
        line-height: 1.25vw;
        margin: 1.6666666666666667vw 0 1.5625vw;
    }

    .wrapper button {
        border: 0;
        padding: 0;
        background-color: transparent;
        color: #6FBA91;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;

        font-size: 0.7291666666666666vw;
        line-height: 171.429%;
        text-decoration-line: underline;
        margin: 0 0 0.9895833333333333vw;
    }
}
</style>
