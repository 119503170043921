<template>
    <div>

        <div class="F1">
            <div class="F1C1">
                <div class="F1C1_C1">
                    <img src="../assets/llamada-telefonica-1.png">
                </div>
                <div class="F1C1_C2">
                    <p>(662) 213.23.02 y (662) 313.06.50</p>
                </div>
                <div class="F1C1_C3">
                    <a @click="showmodal = !showmodal" target="_blank"><img src="../assets/Hamburger_icon_1.png"></a>
                    <div v-if="showmodal == true" class="modalss">

                        <BarraganSM @listenModal="closeModal" @quejas="quejas()" />

                    </div>
                </div>
            </div>
            <div class="F1C2">
                <div class="F1C2_C1">
                    <img src="../assets/email_1.png">
                </div>
                <div class="F1C2_C2">
                    <p>barragan@laason.mx</p>
                </div>
            </div>
            <div class="F1C3">
                <div class="F1C3_C1">
                    <p>Garmendia N°80, Col.San Benito C.P. 83190, Hermosillo, Sonora.</p>
                </div>
            </div>
            <div class="F1C4">
                <div class="F1C4_C1">
                    <a href="https://www.facebook.com/LaboratorioBarragan"><img src="../assets/facebook_2.png"></a>
                    <a href="https://www.instagram.com/laboratoriobarragan/"><img src="../assets/instagram_2.png"></a>
                </div>
            </div>
            <div class="F1C5">
                <a href="/laason">
                    <div class="F1C5_C1">
                        <p>Cambiar a LAASON</p>
                        <img src="../assets/logoMini.svg">
                    </div>
                </a>
            </div>

            <div class="wrapper" id="wrapper-mov">
                <h2>¿Tienes alguna queja o sugerencia para nuestro servicio? </h2>
                <a href="mailto:dirección@laason.mx">Envíanos un mensaje</a>
                <ol>
                    <li>Envíanos un mensaje por medio de este botón o al correo <a
                            href="mailto:dirección@laason.mx">dirección@laason.mx</a>
                    </li>
                    <li>Te confirmaremos de recibida tu queja o sugerencia, si es una queja recibe y
                        analiza las causas que la originaron si procede o no en un plazo máximo de
                        10 días hábiles.</li>
                    <li>Se tomaran las acciones pertinentes y nos comunicaremos contigo.</li>
                </ol>
                <h3>Muchas gracias por ser parte de nuestra mejora continua.</h3>

                <button @click="removeWrapper()">Salir</button>
            </div>
        </div>

    </div>
</template>

<script>

import BarraganSM from './modal/BarraganSM.vue';

export default {
    name: 'HeaderB',
    data() {
        return {
            showmodal: false,
        }
    },
    components: {
        BarraganSM
    },
    methods: {
        closeModal(value) {
            this.showmodal = value;
        },
        mostrarModal(opcion) {
            this.showmodal = opcion;
            console.log(this.showmodal)
        },

        quejas() {
            const wrapper = document.getElementById("wrapper-mov");
            wrapper.classList.add("showing")
        },

        removeWrapper() {
            const wrapper = document.getElementById("wrapper-mov");
            wrapper.classList.remove("showing")
        }
    }
}
</script>

<style scoped>
.modalss {
    position: fixed;
    z-index: 2;
    padding-top: 0vw;
    left: 0;
    top: 14vw;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.selector {
    border: 1px solid white;
    border-radius: 3vw;
    text-align: center;
    padding: 0.36875VW 1.6666666666666667VW;
    margin: 0vw !important;
}

.F1 {
    display: flex;
    width: 100%;
    height: 14.009661835748794VW;
    background-color: #183B68;
}

.F1C1 {
    display: flex;
    align-items: center;
}

.F1C1_C1 {
    width: 14vw;
    text-align: center;
}

.F1C1_C1 img {
    width: 3.864734299516908VW;
    height: auto;
}

.F1C1_C2 {
    width: 73vw;
}

.F1C1_C2 p {
    color: #C2DEFF;
    font-weight: 400;
    font-size: 2.898550724637681VW;
    padding-top: 3vw;
}

.F1C1_C3 {
    display: block;
}

.F1C1_C3 img {
    width: 8.212560386473431VW;
    height: auto;
}

.F1C2 {
    display: none;
}

.F1C3 {
    display: none;
}

.F1C4 {
    display: none;
}

.F1C5 {
    display: none;
}

.F1 {
    position: relative;
    overflow-x: clip;
}

.wrapper {
    display: grid;
    position: absolute;
    z-index: 3;
    top: 100%;
    left: 0%;
    transform: translateX(100%);
    transition: transform 0.3s linear;
    background-image: linear-gradient(0deg, #183B68 81.32%, #548EBA 100%);
    padding: 15.70048309178744vw 11.594202898550725vw 16.183574879227052vw;
}

.wrapper.showing {
    transform: translateX(0%);
}


.wrapper h2 {
    color: #BBEFE3;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;

    font-size: 5.797101449275362vw;
    line-height: 100%;
    margin: 0 0 3.864734299516908vw;
}

.wrapper h2+a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(0deg, #4F8B77 0%, #6FBA91 100%);
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;

    font-size: 5.072463768115942vw;
    line-height: normal;
    height: 13.285024154589372vw;
    border-radius: 12.077294685990339vw;
    width: 100%;
    margin: 0 0 5.072463768115942vw;
}

.wrapper ol {
    padding: 0 0 0 4.830917874396135vw;
}

.wrapper li {
    color: #BBEFE3;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;

    font-size: 4.3478260869565215vw;
    line-height: 133.333%;
}

.wrapper li a {
    color: #BBEFE3;
    font-weight: 700;
}

.wrapper h3 {
    color: #BBEFE3;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;

    font-size: 4.3478260869565215vw;
    line-height: 133.333%;
    margin: 4.830917874396135vw 0 5.555555555555555vw;
}

.wrapper button {
    padding: 0;
    border: 0;
    background-color: transparent;
    width: fit-content;
    margin: 0 auto;
    color: #6FBA91;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;

    font-size: 4.3478260869565215vw;
    line-height: 133.333%;
    text-decoration-line: underline;
}

@media (min-width: 768px) {
    .wrapper {
        display: none;
    }

    .F1 {
        width: 100%;
        height: 3.125VW;
    }


    .F1C1_C1 img {
        width: 0.9375VW;
        height: auto;
    }

    .F1C1_C2 p {
        font-size: 0.6770833333333334VW;
        padding-top: 1vw;
    }

    .F1C1_C3 {
        display: none;
    }

    .F1C2 {
        display: flex;
        align-items: center;
        margin-right: 34.729166666666668VW;
    }

    .F1C1_C1 {
        width: 1vw;
        text-align: center;
        margin-left: 4.322916666666667VW;
        margin-right: 1vw;
    }

    .F1C1_C2 {
        width: 12.4vw;
    }

    .F1C2_C1 {
        margin-right: 1vw;
    }

    .F1C2_C1 img {
        width: 0.9375VW;
        height: auto;
    }

    .F1C2_C2 p {
        font-size: 0.6770833333333334VW;
        padding-top: 1vw;
        color: #C2DEFF;
        font-weight: 400;
    }

    .F1C3 {
        display: flex;
        align-items: center;
        margin-right: 3.958333333333333VW;
    }

    .F1C3_C1 p {
        font-size: 0.6770833333333334VW;
        padding-top: 1vw;
        color: #C2DEFF;
        font-weight: 400;
    }

    .F1C4 {
        display: flex;
        align-items: center;
        margin-right: 4vw;
    }

    .F1C4_C1 {
        width: 3vw;
        justify-content: space-between;
    }

    .F1C4_C1 img {
        width: auto;
        height: 1.25VW;
        margin-right: 0.2vw;
        cursor: pointer;
    }

    .F1C5 {
        display: flex;
        align-items: center;
        background-color: white;
        cursor: pointer;
    }

    .F1C5 a {
        width: 100%;
        height: 100%;
        padding: 1vw 0vw;
    }

    .F1C5_C1 {
        display: flex;
        width: 12.1875VW;
        justify-content: space-between;
        padding: 0vw 2vw;
    }

    .F1C5_C1 p {
        color: #6FBA91;
        font-size: 0.6770833333333334VW;
        font-weight: 900;
        margin: 0vw;
        align-self: center;
    }

    .F1C5_C1 img {
        width: auto;
        height: 1.25VW;
        margin-right: 0.2vw;
        cursor: pointer;
    }


}
</style>
